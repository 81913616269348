const mainConfigs = {
    tokenType: 'Bearer',
    countSearchCity: 7,
    countOrdersLimit: 12,
    serverTimeZone: 'Asia/Dushanbe',
    serverDateFormat: 'DD MMM YYYY, dd',
    serverDateTimeFormat: 'DD MMM YYYY, dd, HH:mm',
    serverDateTimeFormatWithoutYear: 'DD MMM, HH:mm',
    webTransferTimeFormat: 'DD.MM.YYYY HH:mm',
    dateFormatWithoutPoint: 'DD MMM YYYY',
    formatForGroupOrders: 'MMMM YYYY',
    defaultFlightType: 'RT',
    defaultCabin: 'all',
    pageRecLimit: 10,
    friendlyCountriesCodes: ['RU', 'AM', 'BY', 'АЦ', 'KZ', 'ЮО', 'KG'],
    defaultAvailableCurrencies: ['TJS', 'RUB', 'USD'],
    defaultWebCurrency: 'TJS',
    language: localStorage.getItem('language') || 'ru',
};

export default mainConfigs;
