const en = {
  login_text: "Login",
  password_text: "Password",
  forgot_password_text: "Forgot your password?",
  registration_text: "Register",
  enter_text: "Sign in",
  invalid_password: "6 characters minimum",
  invalid_email: "Invalid Email",
  required: "Required field",
  travel_time: "Travel time",
  flight: "Flight",
  terminal: "Terminal",
  baggage: "Baggage",
  hand_luggage: "Hand luggage",
  refund_ticket: "Ticket refund",
  change: "Ticket exchange",
  no_refund: "Non-refundable",
  yes_refund: "Refundable ticket",
  no_change: "No ticket exchange",
  yes_change: "Exchange ticket",
  yes_baggage: "Baggage",
  no_baggage: "No baggage",
  yes_hand_luggage: "Hand luggage",
  no_hand_luggage: "No hand luggage",
  show_rules: "Show rules",
  total: "Total",
  passengers: "Passengers",
  select_from_list: "Select from list",
  name_as_in_document: "First name as in the document",
  middlename_as_in_document: "Middle name as in the document",
  surname_as_in_document: "Last name as in the document",
  date_of_birth: "Date of Birth",
  gender: "Gender",
  citizenship: "Citizenship",
  age: "Age",
  document: "Document",
  payer: "Payer",
  for_payer_mess:
    "We will send booking information to your Email (and WhatsApp). Phone is needed for urgent alerts when schedule changes ",
  series_and_number: "Document number",
  valid_until: "Expiration date",
  airline_bonus_card: "Airline Bonus Card",
  number_bonus_card: "Bonus card number",
  phone_number: "Phone number",
  email: "Email",
  how_to_read_rules: "How to read fare rules",
  tariff_rules: "Fare rules",
  book_and_proceed_to_payment: "Book and proceed to payment",
  agreement_book_link: "the airline fare rules",
  everywhere_local_time:
    "Everywhere indicated local time of departure and arrival",
  duration: "duration",
  in: "in",
  no_data_contact_technical_support: "No data. Contact technical support.",
  back_to_orders: "Back to orders",
  incorrect_date_from_adt: "Invalid date for adult",
  incorrect_date_from_chd: "Invalid date for child",
  incorrect_date_from_inf: "Invalid date for infant",
  incorrect_date: "Invalid date",
  check_date_and_change_doc: "Check date of birth or change document type",
  warning_inf_date:
    "The airline may ask a newborn's certificate of admission to flight",
  ps_should_be_10_digits: "The Russian national passport consists of 10 digits",
  psp_should_be_9_digits:
    "The Russian international passport consists of 9 digits",
  incorrect_doc_number: "Invalid document number",
  hint_for_ps_doc: "Enter passport number as 1234567890",
  hint_for_psp_doc: "Enter passport number as 123456789",
  hint_for_sr_doc: "Enter birth certificate number as IXЯЯ123456",
  hint_for_np_doc: "Enter document number",
  male: "Male",
  female: "Female",
  russian_passport: "Russian national passport",
  birth_certificate: "Russian birth certificate",
  international_passport: "Russian international passport",
  foreign_document: "Foreign document",
  place_one: "place",
  place_more: "places",
  place_many: "places",
  customer_support: "Customer support",
  facebook: "Facebook",
  currency: "Currency",
  language: "Language",
  search: "Search",
  personal_info: "Personal data",
  to_main: "To home page",
  log_out: "Logout",
  session: "Session",
  balance: "Balance",
  otb: "Balance",
  own_funds: "Own funds",
  credit_line: "Credit line",
  no: "No",
  for_route: "For route",
  only_a_z_space: "Latin symbols and space only",
  only_a_z: "Latin symbols only",
  invalid_phone: "Wrong phone number",
  min_2_symbol: "2 characters minimum",
  select_value_from_list: "Select value from the list",
  check_date: "Check the date",
  incorrect_number: "Invalid card number",
  incorrect_cvc: "Invalid code",
  incorrect_card_holder: "Invalid card holder name",
  incorrect_card_month: "Invalid month",
  incorrect_card_year: "Invalid year",
  code: "Сode",
  message: "Message",
  close: "Close",
  error_message_part_1:
    "It seems something went wrong. For our understanding causes of the problem, please contact tech support",
  error_message_part_2: "and tell the session number",
  error_message_part_3:
    "After closing the message, you will redirected to the main page of the service. ",
  choose: "Select",
  collapse: "Collapse",
  looking_tickets: "We're searching tickets",
  wait_please: "Please, wait",
  we_issue_tickets: "We're issuing tickets, it doesn't take too much time",
  created: "Created",
  left: "Left",
  less_than_minute: "less than a minute",
  help: "Help",
  exchange_and_return_rules: "Exchange and return: rules",
  after_flight: "After the flight",
  go_to_section: "Go to section",
  next: "Next",
  not_book_tickets: "You have no booked orders",
  route_from: "From",
  route_to: "To",
  date_from: "Date",
  date_to: "Return date",
  find: "Search",
  one_way: "One way",
  two_way: "Round trip",
  multi_way: "Multiple flight",
  economy: "Economy",
  business: "Business",
  first: "First",
  all_class: "Any class",
  adt_main_label: "Adult",
  inf_main_label: "Infant",
  chd_main_label: "Child",
  adt_second_label: "over 12 years old",
  chd_second_label: "2-12 years old",
  inf_second_label: "up to 2 years (without seat)",
  inf_with_seat_second_label: "with seat",
  passenger_1: "One passenger",
  passenger_2: "Two passengers",
  passenger_3: "Three passengers",
  passenger_4: "Four passengers",
  passenger_5: "Five passengers",
  passenger_6: "Six passengers",
  agents: "For agents",
  passenger_7: "Seven passengers",
  passenger_8: "Eight passengers",
  passenger_9: "Nine passengers",
  only_there: "One way",
  roundtrip: "Round trip",
  client_name: "Client name",
  client_code: "Client code",
  partner_code: "Partner code",
  partner_name: "Partner name",
  partner_currency: "Partner currency",
  current_balance: "Actual balance",
  credit_limit: "Credit limit",
  deposit: "Deposit",
  save: "Save",
  departure_title: "Departure",
  there_title: "There",
  back_title: "Back",
  validates_flights: "validates flights",
  hour_short: "h",
  day_short: "d",
  minutes_short_3: "min",
  on_way: "On the way",
  no_transfers: "Direct flight",
  class: "Class",
  tariff_options: "Tariff options",
  with_luggage: "With baggage",
  without_luggage: "Without baggage",
  price: "Price",
  detail: "More info",
  label_price_by_min: "Price (increase)",
  label_price_by_max: "Price (descrease)",
  transfers: "Transfers",
  all_flights: "All flights",
  without_transfers: "Direct flight",
  one_transfer: "1 transfer",
  many_transfer: "2 transfers and more",
  not_found_recomm_first_text:
    "We could not find tickets matching Your request ",
  not_found_recomm_second_text:
    "Please try again with others dates or airport ",
  not_found_recomm_by_filters: "Please change the filter conditions",
  flight_details: "Flight details",
  change_tariff: "Change tariff",
  tariffs: "Tariffs",
  teh_stop: "Technical stop",
  transfer: "Transfer",
  local_time_message: "Local departure and arrival times",
  route_tariffs_message: "The tariff is valid for the entire route",
  airlines: "Airlines",
  airports: "Airports",
  departure: "Departure",
  arrival: "Arrival",
  departure_time: "Departure time",
  arrival_time: "Arrival time",
  flight_number: "Flight number",
  multiple_numbers_separated_by_spaces: "Multiple numbers separated by spaces",
  lowcost: "lowcost",
  transfer_1: "transfer",
  transfer_2_4: "transfers",
  transfer_5: "transfers",
  free_seats_0: "no free seats",
  free_seats_1: "seat",
  free_seats_2_4: "seats",
  free_seats_5: "seats",
  one_adt: "Adult",
  more_adt: "Adults",
  many_adt: "Adults",
  one_chd: "Child",
  more_chd: "Children",
  many_chd: "Children",
  one_inf: "Infant",
  more_inf: "Infants",
  many_inf: "Infants",
  flight_one: "flight",
  flights_more: "flights",
  flights_many: "flights",
  found_one: "Found",
  found_more: "Found",
  flight_overview: "Flight overview",
  to_top: "Page up",
  book: "Booking",
  buyer: "Payer",
  pnr: "Booking code / PNR",
  online_registration_locator: "Online registration locator",
  route_information: "Flight information",
  order: "Order",
  flights_tickets: "Tickets",
  void_text: "You can cancel the order at any time",
  void_order: "Cancel the order",
  payment_method: "Payment options",
  left_before_the_time_limit: "left before the time limit is up",
  expires: "Ends",
  payment_timed_out: "Time for payment is up",
  time_expired: "Time is up",
  order_created: "Order created",
  return_ticket: "Return ticket",
  download_itinerary_receipt: "Download itinerary receipt",
  order_cost: "Order price",
  void_in_process: "Goes order cancellation",
  initial: "Order created",
  success: "Successful payment, tickets issued",
  booking_fail: "Booking error",
  order_fail: "Error creating order",
  pay_fail: "Payment error",
  order_cancel_from_client: "Order canceled by customer",
  order_cancel_from_admin: "Order canceled by administrator",
  order_cancel_by_job: "Order canceled by time limit",
  order_cancel_error: "Error canceling order",
  application_refund: "Refund application is created",
  partial_refund: "Partial order refund",
  refund: "Full order refund",
  ticketing_done: "Successful payment, tickets issued",
  pay_success: "Successful payment, tickets are issuing",
  booking_done: "Booked",
  exchange: "Ticket exchange",
  void: "Order canceled",
  ticketing_fail: "Error issuing tickets",
  year_one: "year",
  year_more: "years",
  years: "years",
  day_one: "day",
  day_more: "days",
  days: "days",
  hour_one: "hour",
  hour_more: "hours",
  hours: "hours",
  minute_one: "minute",
  minute_more: "minutes",
  minutes: "minutes",
  second_one: "second",
  second_more: "seconds",
  seconds: "seconds",
  your_name: "Your name",
  search_order: "Search order",
  locator: "Locator",
  cancel: "Cancel",
  do_not_find_order_message:
    "Order not found. Please change your search options",
  orders: "Orders",
  main: "Main",
  users: "Users",
  all_orders_label: "All orders",
  book_done_label: "Booked",
  pay_done_label: "Paid",
  ticket_done_label: "Ticketed",
  cancel_done_label: "Canceled",
  wait_refund_label: "Waiting for refund",
  refund_done_label: "Refunded",
  partial_refund_done_label: "Partially refunded",
  void_done_label: "Void",
  error_order_label: "Order error",
  date_create_from: "Creation date from",
  order_status_label: "Order status",
  search_title: "Enter last name, locator or city",
  date_create_to: "Creation date to",
  clear: "Clear",
  passenger_success_save: "Passenger saved successfully",
  payment_with_dpt: "Pay with balance",
  payment_with_unt: 'Pay with "Uniteller"',
  payment_with_spt: 'Pay with "Corti Milli"',
  before: "to",
  month_one: "month",
  months_more: "months",
  months: "months",
  all_passengers: "All passengers",
  companies: "Сompanies",
  no_passengers: "You have no saved passengers yet",
  value: "Value",
  reset: "Reset",
  invalid_value: "Invalid value",
  no_orders_found: "No orders found",
  personal_data: "Personal data",
  upload: "Upload",
  your_logo: "Your logo",
  image_too_large: "Image too large",
  image_resolution_too_high: "Image resolution too high",
  image_resolution_too_small: "Image resolution too small",
  amount: "Amount",
  rules_text_1: "Pay attention to the following phrases in the section",
  rules_text_2: "non refundable ticket",
  rules_text_3: "ticket is non-refundable in case of no-show",
  rules_text_4: "Exchange rules are described in the subtitled section",
  line: "Line",
  rules_text_5:
    "means that changes are not allowed so it's impossible to change flight dates",
  passenger_data: "Passenger data",
  comment: "Comment",
  remove: "Delete",
  created_at: "Created",
  phone: "Phone",
  create_user: "Create user",
  add: "Add",
  edit: "Edit",
  confirm: "Confirm",
  record_one: "record",
  record_more: "records",
  record_many: "records",
  security_guarantees_unitaller: "Security guarantees",
  activation_title: "User Activation",
  confirmation_activation_password: "Confirm password",
  activation: "Activation",
  login: "Login",
  error: "Error",
  to_login_form: "To login page",
  expired_date: "Expired",
  refund_button_title: "Refund",
  itinerary_receipt: "Itinerary receipt",
  action: "Action",
  to_pay: "Payment",
  order_cancel_error_mess: "Error cancelling order",
  order_cancel_successfully_mess: "Cancellation successful",
  partner: "Partner",
  inn: "INN",
  kpp: "KPP",
  logo: "Logo",
  status: "Status",
  return_request_created: "Refund application is created",
  error_creating_refund_request: "Error creating refund application",
  placeholder_date_picker: "dd.mm.yyyy",
  transactions: "Transactions",
  weight_unit: "kg",
  approved_balance_request_successfully:
    "Application for statement of balance confirmed",
  error_approved_balance_request_successfully:
    "Error confirming application for statement of balance",
  balance_payment_request: "Balance payment request",
  balance_payment_approved: "Balance payment approved",
  payment_with_uzc: 'Pay with "Uzcard"',
  client: "Client",
  was_changed_to: "was changed to",
  registration: "Registration",
  fare: "Fare",
  fees: "Fees",
  partner_fees: "Partner fees",
  taxes: "Taxes",
  ticket: "Ticket",
  pay_for_the_order: "Pay for the order",
  operating_company: "Operating company",
  download_charter_certificate: "Download charter certificate",
  certificate_issued: "Charter certificate issued",
  charter: "Charter",
  service: "Service",
  income: "Income",
  spending: "Spending",
  remainder: "Remainder",
  description: "Description",
  contract: "Contract",
  no_result: "No result",
  date: "Date",
  order_num: "Order number",
  payment_with_vsl: 'Pay with "Corti Milli" & "VISA"',
  popular_destinations: "Popular destinations",
  more_options: "More options",
  straight: "Straight",
  with_transfer: "With transfers",
  contacts: "Contacts",
  difficult_routes: "Make a difficult route",
  search_history: "Search history",
  find_tickets: "Find tickets",
  tours_discount: "Favorable tours and discounts",
  last_news: "The latest news",
  more_details: "More details",
  about_company: "About company",
  customer_rew: "Customer reviews",
  my_profile: "My profile",
  contact_us: "Contact us",
  last_name: "Last name",
  fist_name: "First name",
  enter_name: "Enter your name",
  enter_surname: "Enter your last name",
  enter_password: "Enter your password",
  log_in: "Log in",
  return_to_simple_route: "Return to simple route",
  delete: "Delete",
  max_num_flights: "You added maximum number of flights",
  select_dep_date: "Select departure date",
  select_return_date: "Select return date",
  select_class: " Select class",
  login_as_user: "Log in as a user",
  enter_recieved_code: "Enter the recieve code",
  come_up_your_pass: "Come up with your password",
  enter_valid_mail: "Enter valid email",
  must_field_characters: "Field must contain at least 6 characters",
  register_to_save_data: "Please register in order to save your information",
  ins_main_label: " Infant",
  ins_second_label: " up to 2 years (with seat)",
  newsletter_subscription: "Newsletter subscription",
  newsletter_subscription_text:
    "Subscribe to keep abreast of all important news and great deals!",
  subscribe: "Subscribe",
  successfully_subscribed: "You have successfully subscribed!",
  subscription_error: "Subscription error, please try again later!",
  all_news: "All news",
  tel: "Tel",
  enter_email: "Enter your email",
  menu: "Menu",
  settings: " Settings",
  deposit_method: "Select deposit method",
  recharging_amount: "Recharging amount",
  recharge: " Recharge",
  recharging_balance: "Recharging balance",
  done: " Done",
  not_found_content: " Not found",
  not_found_content_text:
    " Unfortunately, this content is not available in the selected language",
  airport_code: "Airport code",
  from_city: "From",
  to_city: "To City",
  weekdays: "Days of the week",
  airline: "Airline",
  min_number: "Minimum amount must be greater than ",
  only_numbers: " Only numbers",
  daily: "Daily",
  fly_tagline: "Buy a ticket in 2 clicks",
  sorting: "Sorting",
  i_read_fares: "I read",
  i_accept_fares: "and I accept them all",
  prices_table: "Prices table",
  continue: "Continue",
  reset_error_title: "Error",
  reset_error_content: "Sorry, error occured while processing your request",
  reset_success_title: "You successfully changed your password",
  reset_success_content:
    'Please, click on "Login" button to log in with new password',
  forgot_success_title: "Success!",
  forgot_success_content: "Reset link has been sent to your E-Mail",
  forgot_error_title: "Error",
  forgot_error_content: "Sorry, but you entered wrong E-Mail",
  password_confirm: "Password Confirmation",
  password_confirm_error: "Passwords aren't the same",
  enter_password_confirm: "Confirm the password",
  enter_new_password: "Please enter your new password",
  forgot_password: "Forgot Password?",
  forgot_password_summary: "Enter your E-Mail to reset your password",
  payment: 'Payment by "Korti milli"',
  from_airport: "From airport",
  not_enough_balance: "Balance isn't enough for payment",
  payment_methods: "Payment Methods",
  our_partners: "Our partners",
  confirm_actions: "Confirm your action on the site",
  payer_contacts: "Payer contacts",
  buyer_email: "Buyer's email",
  enter_buyer_email: "Enter buyer's email",
  locator_order_number_or_booking_number:
    "Locator, order number or booking number",
  enter_locator_order_number_or_booking_number:
    "Enter locator, order number or booking number",
  activate: "Activate",
  register: "Register",
  page_home: "Online ticket purchase, without visiting the ticket office.",
  page_faq: "Answers to popular questions",
  page_login: "Log in to your personal account",
  page_registration: "Register and buy tickets",
  page_results: "Results for the route of interest",
  page_booking: "Booking a given ticket",
  page_order: "Information about the completed order",
  page_orders_list: "Information about completed orders",
  page_my_profile: "Personal information",
  page_add_balance: "Replenishment of the balance of the personal account",
  page_popular_destinations: "Popular departures schedule",
  page_news: "Popular aviation news and Fly TJ",
  page_forgot_password: "Buying air tickets Online without leaving your home!",
  page_reset_password: "Buying air tickets Online without leaving your home!",
  useful_info: "Useful information",
  copy_link: "Copy link",
  copy_order_link_text: "Copy the link to share access to this order",
  link_copied_to_clipboard: "Link copied to clipboard",
  unsubscribe_success: "You unsubscribed successfully",
  unsubscribe_error: "Error occured while processing the request",
  activation_code_send_to_email:
    "A confirmation code will be sent to your email address",
  choose_gender: "Choose gender",
  old_information: "Information is out of date!",
  refresh: "Refresh",
  refresh_page:
    "Airfare prices are updated several times a day. Refresh the page to check the current prices.",
  will_take_2_minutes: "It can take some time",
  additional_options: "Additional options",
  additional_options_not_found: "Additional options not found!",
  your_id: "Your ID",
  cheapest_without_baggage: "Cheapest without baggage",
  cheapest_with_baggage: "Cheapest with baggage",
  most_convenient_transfes: "The most convenient transfer",
  refund_order: "Refund statement",
  refund_reason: "Refund reason",
  order_payment_method: "Order payment method",
  payer_user_id: "Payer's ID",
  four_last_digits_of_card: "Last 4 digits of card",
  user_id: "User's ID",
  buyer_phone: "Buyer's phone",
  requisite_of_account_to_return: "Bank account number for returning money",
  requisite_of_account: "Bank account number",
  passenger_name_and_surname: "Full name of the passenger",
  passport_serial_and_number: "Series and passport number",
  passport_copy: "Passport copy",
  send: "Send",
  payment_from_client_balance: "Payment via balance",
  payment_from_card: "Payment via card",
  technical_support: "Technical Support",
  buy_tickets_to_all_destinations: "and buy tickets to all destinations!",
  filters: "Filters",
  night_flight: "Night transfer",
  change_airport: "Change airport",
  and: "and",
  from: "from",
  refund_order_success: "Refund application sent successfully",
  refund_order_error: "Error occured while sending application",
  buy_tour: "Buy tour",
  search_limits_exceeded:
    "Dear user, you exceeded search limit, please authorize in order to continue",
  flight_without_seats: "Unfortunately, there are no seats in this class, but there are seats in economy class.",
  cashback: "Cashback",
  or_use_socials: "or use the social media account",
  insurance: "Insurance",
  organization_info: "Limited Liability Company «ANTARES»",
  organization_address: "Actual address: Republic of Tajikistan, Dushanbe city, Bukhoro street 32-34,",
  inn_address: "INN 030018191",
  ein_address: "EIN 0310005585",
  we_are_multifaceted: "We are multifaceted",
  who_are_we: "Who are we?",
  fly_information: `
    “FLY.TJ” has been on the market since 2010 and has become one of the most successful companies in the air transportation industry. 
    We also have the official status of IATA “IATA Accredited Agent”,
    Now we are the first and only company in Tajikistan,
    having its own national online booking system with access and direct partnership with a large number of international airlines such as
    a large number of international airlines such as:
    Turkish Airlines, FlyDubai, Air Astana, Uzbekistan Airways, Belavia, China Southern and others.
  `,
  our_advantages: "Our advantages",
  our_services: `
  The range of services provided by our company is very diverse.
  In addition to passenger air transportation,
  our specialists will also help you in organizing your business trip (book a hotel, issue an insurance policy and visa).
  FLY.TJ” company is a leader in selling air tickets to all destinations on the air transportation market.
  During this time we have developed and optimized online system for the sale of air tickets,
  which has no analogues in Tajikistan. This system allows you to easily and simply evaluate,
  book and buy air tickets independently on the site.
  `,
  convenience: "Convenience",
  user_friendly_interface: "User-friendly interface",
  access247: "24/7 access",
  purchase_of_tickets_to_any_destinations: "Purchase of tickets to any destinations",
  uniqueness: "Uniqueness",
  access_to_a_unique_product: "Access to a unique product",
  always_on_the_phone: "Always on the phone",
  customer_information_support_247: "Customer information support 24/7",
  without_providing_data_to_third_parties: "Without providing data to third parties",
  privacy: "Privacy",
  safety: "Safety",
  secure_connection: "Secure connection",
  professionalism: "Professionalism",
  prompt_service: "Prompt service",
  soft: "Soft",
  own_booking_system: "Own booking system",
  we_will_send_the_ticket_to_your_inbox_within_5_minutes: "We will send the ticket to your inbox within 5 minutes.",
  go_to_the_main_page: "Go to the main page",
  your_order_has_been_successfully_paid_for: "Your order has been successfully paid for!",
  the_ticket_will_be_sent_to_your_email_within_1_2_minutes: "The e-ticket will be sent to your email within 1-2 minutes",
  mon: "mon",
  tues: "tues",
  wed: "wed",
  thur: "thur",
  fri: "fri",
  sat: "sat",
  sun: "sun",
  show_more: "Show more",
  go_to_the_payment_page: "Go to the payment page",
  no_orders_to_confirm_payment: "No orders to confirm payment",
  go_to_the_orders_page: "Go to the orders page",
  change_the_tariff: "Change the tariff: Baggage, class, etc.",
  add_baggage: "Change tariff",
  baggage_included: "Baggage included",
  fare_without_baggage: "Fare without baggage",
  share: "Share",
  passenger_search: "Passenger Search",
  this_passenger_is_not_found_on_your_list: "This passenger is not found on your list",
  go_to_orders_page: "Go to orders page",
};

export default en;
