const defaultCurrencies = {
    uz: {
        RUB: 'Рубл',
        TJS: 'Сомонӣ',
        USD: 'Доллар',
        UZS: 'Узбек сум',
    },
    ru: {
        RUB: 'Рубль',
        TJS: 'Сомони',
        USD: 'Доллар',
        UZS: 'Узбекский сум',
    },
    en: {
        RUB: 'Rubl',
        TJS: 'Somoni',
        USD: 'Dollar',
        UZS: 'Uzbek sum',
    },
};

export default defaultCurrencies;
