const defaultLanguages = {
    ru: {
        ru: 'Русский',
        uz: 'Узбекский',
        en: 'Английский',
    },
    uz: {
        ru: 'Русӣ',
        uz: 'Ozbekcha',
        en: 'Англисӣ',
    },
    en: {
        ru: 'Russian',
        uz: 'Uzbek',
        en: 'English',
    },
};

export default defaultLanguages;
